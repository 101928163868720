@use '../custom-variables/colors' as *;
@use '../custom-variables/grid' as gd;

.header-section {
  .main-header-section {
    background: url('/img/bg/domain-main-page-bg.svg') no-repeat,
    #{$header-default-bg-gradient};
    background-position: center;
    padding-top: 3rem;

    .domain-check-label {
      display: flex;
      flex-direction: column;
      align-items: center;

      .gpt-toggler {
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;

        cursor: pointer;
      }
    }

    .page-benefits-block {
      margin-bottom: 4.5rem;
    }
  }
}

.content {
  .domain-selection-block {
    padding-block: 2rem;

    background: $domain-page-content-domain-selection-block-bg;

    .domain-check-label {
      color: $domain-page-content-domain-selection-block-label-color;

      font-size: 30px;
      font-weight: 600;
      line-height: 1.3;

      margin-right: 1rem;

      display: flex;
      flex-direction: column;
      align-items: center;

      .gpt-toggler {
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;

        cursor: pointer;
      }
    }

    .domain-input {
      margin-right: 4rem;
    }

    @media (max-width: gd.get-grid-bp('lg')) {
      .domain-input {
        margin-right: 2rem;
      }
    }

    @media (max-width: gd.get-grid-bp('md')) {

      .domain-check-label,
      .domain-input {
        margin-right: 0;
      }
    }
  }
}
